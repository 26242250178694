import React, { Component } from 'react'
import { connect } from 'react-redux'
import InputMask from 'react-input-mask'
import Swal from 'sweetalert2'
import DatePicker, { registerLocale } from 'react-datepicker'
import { ToastContainer, toast } from 'react-toastify'
import { withRouter } from 'react-router'

import { MdDataUsage, MdAddCircle, MdGroup, MdSort } from 'react-icons/md'

import 'react-datepicker/dist/react-datepicker.css'
import 'react-toastify/dist/ReactToastify.css'

import ptbr from 'date-fns/locale/pt'

import Sidebar from './../../components/sidebar'
import PageTitle from './../../components/pageTitle'
import Footer from './../../components/footer'
import DadosTableRow from './../../components/dados_tableRow'

import cidades from './../../assets/cidades.json'
import estados from './../../assets/estados.json'
import setor from './../../assets/setor.json'
import currency from './../../assets/currency.json'

import { ResetSpread, loadAllDataFromAPI } from './../../services/index'

import * as SidebarActions from '../../redux/store/actions/sidebar'
import * as DadosActions from '../../redux/store/actions/dados_datas'
import * as AtivoActions from '../../redux/store/actions/ativo'
import * as PassivoActions from '../../redux/store/actions/passivo'
import * as MutacaoActions from '../../redux/store/actions/mutacao'
import * as DREAction from '../../redux/store/actions/dre'

import { listSpreadsDates, reorderDates } from '../../services/automaticSpread'
import { IS_SULBRASIL } from '../../config/env'
import {
  dynamicMask,
  formatWithTimezone,
  isValidDocument,
  redirectIfNotContainsDocument
} from '../../utils'
import { loadData } from '../../services/updateREDUX'
import { saveAllData } from '../../lib/save-data'

registerLocale('ptbr', ptbr)

class Dados extends Component {
  constructor (props) {
    super(props)

    this.estadoRef = React.createRef()
    this.cidadeRef = React.createRef()
    this.setor = React.createRef()
    this.moeda = React.createRef()

    this.state = {
      selectedGroup: {},
      inputOptions: undefined,
      uploadStatus: [],
      nome_empresa: {
        valid: false,
        value: this.props.dados.nome_empresa,
        first: true
      },
      cnpj: {
        valid: this.validDocument(this.props.dados.cnpj),
        value: this.props.dados.cnpj,
        first: true,
        justNumbers: (this.props.dados.cnpj || '').toString().replace(/\D/g, '')
      },
      grupo: {
        valid: false,
        value: this.props.dados.grupo,
        first: true
      },
      estado: {
        valid: false,
        value: this.props.dados.estado,
        first: true
      },
      cidade: {
        valid: false,
        value: this.props.dados.cidade,
        first: true
      },
      // cidades: this.props.dados.show_cidade
      //   ? cidades.filter(item => {
      //     return item.Estado === this.props.dados.estado
      //   })
      //   : [],
      setor: {
        valid: false,
        value: this.props.dados.setor,
        first: true
      },
      fundacao: {
        valid: false,
        value: this.props.dados.fundacao,
        first: true
      },
      dataBase: {
        valid: false,
        value: this.props.dados.dataBase,
        first: true
      },
      moeda: {
        valid: false,
        value: this.props.dados.moeda,
        first: true
      },
      gerente: {
        valid: false,
        value: this.props.dados.gerente,
        first: true
      },
      plataforma: {
        valid: false,
        value: this.props.dados.plataforma,
        first: true
      },
      comentarios: {
        valid: false,
        value: this.props.dados.comentarios,
        first: true
      },
      showCidades: !!this.props.dados.cidade,
      cidades: (cidades || []).filter(item => {
        return item.Estado === this.props.dados.estado
      }),
      datas: []
    }

    this.props.clickSidebar(0)
  }

  // eslint-disable-next-line
  async componentWillReceiveProps (propsPrev) {
    this.setState({
      nome_empresa: {
        ...this.state.nome_empresa,
        value: propsPrev.dados.nome_empresa
      },

      cnpj: {
        ...this.state.cnpj,
        value: propsPrev.dados.cnpj,
        valid: this.validDocument(propsPrev.dados.cnpj),
        justNumbers: (propsPrev.dados.cnpj || '').toString().replace(/\D/g, '')
      },

      grupo: {
        ...this.state.grupo,
        value: propsPrev.dados.grupo
      },

      estado: {
        ...this.state.estado,
        value: propsPrev.dados.estado
      },

      cidade: {
        ...this.state.cidade,
        value: propsPrev.dados.cidade
      },

      setor: {
        ...this.state.setor,
        value: propsPrev.dados.setor
      },

      fundacao: {
        ...this.state.fundacao,
        value: propsPrev.dados.fundacao
      },

      dataBase: {
        ...this.state.dataBase,
        value: propsPrev.dados.dataBase
      },

      moeda: {
        ...this.state.moeda,
        value: propsPrev.dados.moeda
      },

      gerente: {
        ...this.state.moeda,
        value: propsPrev.dados.gerente
      },

      plataforma: {
        ...this.state.moeda,
        value: propsPrev.dados.plataforma
      },
      comentarios: {
        ...this.state.moeda,
        value: propsPrev.dados.comentarios
      },
      showCidades: !!propsPrev.dados.cidade,
      cidades: cidades.filter(item => {
        return item.Estado === propsPrev.dados.estado
      })
    })
  }

  handleChangeEstado = async event => {
    if (event.target.value === 'Selecione') {
      this.setState({
        estado: {
          value: '',
          valid: false,
          first: false
        },
        showCidades: false,
        cidades: []
      })
      this.props.updateData('estado', event.target.value, false)
      this.props.updateSimpleData('show_cidade', false)
    } else {
      const cidadeTemp = cidades.filter(
        item => item.Estado === event.target.value
      )
      const valueUser = event.target.value

      await this.setState({
        estado: {
          value: valueUser,
          valid: true,
          first: false
        },
        showCidades: true,
        cidades: cidadeTemp
      })

      this.props.updateData('estado', valueUser, true)
      this.props.updateSimpleData('show_cidade', true)

      await this.setState({ showCidades: true })
    }
  }

  handleChangeCidade = async event => {
    this.setState({
      cidade: {
        value: event.target.value,
        first: false,
        valid: true
      }
    })
    this.props.updateData('cidade', event.target.value, true)
  }

  handleChangeSetor = async event => {
    if (event.target.value === 'selecione') {
      this.setState({
        setor: {
          value: '',
          valid: false,
          first: false
        }
      })
      this.props.updateData('setor', event.target.value, false)
    } else {
      this.setState({
        setor: {
          value: event.target.value,
          valid: true,
          first: false
        }
      })
      this.props.updateData('setor', event.target.value, true)
    }
  }

  handleChangeDateFundacao = async date => {
    this.setState({
      fundacao: {
        value: date,
        first: false,
        valid: true
      }
    })
    this.props.updateData('fundacao', date, true)
  }

  handleChangeDataBasa = async date => {
    this.setState({
      dataBase: {
        value: date,
        first: false,
        valid: true
      }
    })
    this.props.updateData('dataBase', date, true)
  }

  handleChangeMoeda = async event => {
    if (event.target.value === 'selecione') {
      this.setState({
        moeda: {
          value: 'selecione',
          valid: false,
          first: false
        }
      })
      this.props.updateData('moeda', event.target.value, false)
    } else {
      this.setState({
        moeda: {
          value: event.target.value,
          valid: true,
          first: false
        }
      })
      this.props.updateData('moeda', event.target.value, true)
    }
  }

  handleSortDates = async () => {
    if (
      !this.state.cnpj.value ||
      this.state.cnpj.value.length === 0 ||
      !this.state.cnpj.valid
    ) {
      Swal.fire({
        title: 'Erro',
        text: 'Informe um CNPJ / CPF válido',
        type: 'error',
        confirmButtonText: 'Ok'
      })

      return
    }

    try {
      Swal.fire({
        title: 'Aguarde',
        text: 'Ordenando datas',
        type: 'info',
        onBeforeOpen: () => {
          Swal.showLoading()
        },
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false
      })

      await reorderDates({
        document: this.props.dados.cnpj,
        isGroup: this.props.isGroup
      })

      await Swal.fire({
        title: 'Datas ordenadas com sucesso',
        text: 'Ao confirmar a página será recarregada',
        type: 'success',
        confirmButtonText: 'Ok'
      })
      window.location.reload()
    } catch (err) {
      console.error(err)
      Swal.fire({
        title: 'Erro',
        text: 'Ocorreu um erro ao tentar ordenar as datas',
        type: 'error',
        confirmButtonText: 'Ok'
      })
    }
  }

  handleListSpreads = async () => {
    Swal.fire({
      title: 'Carregando spreads',
      text: 'Aguarde um momento...',
      showConfirmButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading()
      }
    })
    try {
      const spreads = await listSpreadsDates({
        documents: (this.props.dados.empresas_consolidadas || [])
          .filter(item => item.valid)
          .map(item => item.value)
          .concat(this.state.cnpj.value)
          .filter(Boolean)
      })

      if (spreads.length === 0) {
        toast('Nenhum spread encontrado', {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
          type: toast.TYPE.WARNING
        })
      }

      const spreadGroupedByDate = spreads.reduce((obj, item) => {
        const dates = Array.from(
          new Set(
            item.dates.map(date => formatWithTimezone(date, 'yyyy-MM-dd'))
          )
        )

        dates.forEach((date, index) => {
          if (!obj[date]) {
            obj[date] = []
          }

          obj[date].push({
            cnpj: item.document
          })
        })

        return obj
      }, {})

      this.setState({
        groupSpreads: spreadGroupedByDate
      })
      Swal.close()
    } catch (err) {
      Swal.close()
      console.error(err)
      Swal.fire({
        title: 'Erro',
        text: 'Ocorreu um erro ao tentar importar o spread',
        type: 'error',
        confirmButtonText: 'Ok'
      })
    }
  }

  async clickSave () {
    await Swal.fire({
      title: 'Salvar Informações',
      text: 'Deseja salvar as informações inseridas ? As datas inseridas serão bloqueadas para evitar erros nos cálculos',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#525ad0',
      cancelButtonColor: '#272c84'
    })
      .then(async result => {
        if (!result.dismiss) {
          let error = 0
          if (this.state.nome_empresa === '') {
            error++
          }
          if (this.state.cnpj === '') {
            error++
          }

          if (error > 0) {
            Swal.fire(
              'Erro no formulário',
              'Existem alguns campos sem preenchimento, por favor revise',
              'error'
            )
          } else {
            await this.props.lockDates()
            await saveAllData(this.props).then(async ok => {
              if (!ok) {
                return
              }

              await this.props.saveServer()

              toast('Informações salvas no servidor', {
                className: 'toast-background',
                bodyClassName: 'toast-body',
                progressClassName: 'toast-progress',
                position: 'top-right',
                hideProgressBar: true,
                style: {
                  borderRadius: '10px'
                }
              })
              redirectIfNotContainsDocument(
                this.props.match,
                this.props.dados.cnpj,
                this.props.history
              )
            })
          }
        }
      })
      .catch(e => {
        console.error(e)
        toast(
          <div>
            <div>Erro servidor</div>
            <div
              style={{
                borderTop: '1px dashed rgba(255,255,255,1)',
                fontSize: '12px',
                padding: '5px 0',
                marginTop: '5px'
              }}
            >
              Por favor contate o suporte do BOA NOTA.
            </div>
          </div>,
          {
            className: 'toast-red',
            bodyClassName: 'toast-body',
            progressClassName: 'toast-progress',
            position: 'top-right',
            hideProgressBar: true,
            style: {
              borderRadius: '10px',
              background: 'red'
            }
          }
        )
      })
  }

  isValidDate (d) {
    return d instanceof Date && !isNaN(d)
  }

  validDocument (document) {
    return isValidDocument(document)
  }

  async changeToGroupMode () {
    const { dispatch } = this.props
    Swal.fire({
      title: 'Carregando informações'
    })
    Swal.showLoading()

    try {
      await loadData({
        cnpj: this.state.cnpj.value,
        dispatch,
        isGroup: true
      })
      Swal.close()
    } catch (err) {
      if (err.message !== 'Document not found') {
        console.error(err)
        Swal.close()
        Swal.hideLoading()
        Swal.fire({
          title: 'Erro',
          text: err.message,
          type: 'error',
          confirmButtonText: 'Ok'
        })
        return
      }

      try {
        await loadAllDataFromAPI(this.state.cnpj.value, false)
        Swal.close()
      } catch (err) {
        try {
          await ResetSpread({
            dados: this.props.dados,
            isGroup: false
          })
          await loadData({
            cnpj: this.state.cnpj.value,
            dispatch,
            isGroup: false
          })
          Swal.close()
        } catch (err) {
          Swal.close()
          Swal.hideLoading()
          Swal.fire({
            title: 'Erro',
            text: err.message,
            type: 'error',
            confirmButtonText: 'Ok'
          })
        }
      }
    }
  }

  resetButton () {
    return (
      <button
        className='btn btn-danger btn-sm btn-reset'
        style={{ height: '40px' }}
        onClick={() => {
          Swal.fire({
            title: 'Resetar Spread ?',
            text: 'Esse spread terá seus dados apagados para que possa reiniciar a inserção. Para confirmar insira a senha master de controle',
            input: 'password',
            inputPlaceholder: 'digite aqui a senha master',
            inputAttributes: {
              autocapitalize: 'off'
            },
            showCancelButton: true,
            cancelButtonColor: 'red',
            confirmButtonColor: 'red',
            icon: 'question'
          }).then(result => {
            if (result.value === 'c8ag565z') {
              Swal.fire({
                title: 'Spread Reset',
                text: 'Por favor aguarde alguns segundos',
                icon: 'info',
                onBeforeOpen: () => {
                  Swal.showLoading()
                  ResetSpread({
                    dados: this.props.dados,
                    isGroup: this.props.isGroup
                  }).then(resSuccess => {
                    Swal.fire({
                      title: 'Spread Reiniciado',
                      text: 'Este spread foi reiniciado com sucesso, sua página será atualizada',
                      icon: 'info',
                      allowOutsideClick: false
                    }).then(res => {
                      window.location.reload()
                    })
                  })
                }
              })
            } else {
              Swal.fire(
                'Senha Incorreta',
                'Este SPREAD não foi resetado. Em caso de dúvidas contate o suporte BOA NOTA',
                'error'
              )
            }
          })
        }}
      >
        resetar spread
      </button>
    )
  }

  render () {
    const { dados, addData, updateData } = this.props

    return (
      <div className='container-fluid min-vh-100' style={{ padding: '0' }}>
        <div className='row min-vh-100 no-gutters'>
          <Sidebar />
          <div className='content col-auto'>
            <PageTitle
              title='Spread • MMC'
              icon={MdDataUsage}
              btnSalvar={this.clickSave.bind(this)}
              redux_data={dados}
              btnExternal={this.resetButton()}
            />
            <div className='bodyContent'>
              <form className='col-12 form-inline'>
                <div className='form-group'>
                  <label htmlFor='nome' className='label'>
                    Nome da Empresa
                  </label>
                  <input
                    type='text'
                    className={
                      'form-control col-12 ' +
                      (this.state.nome_empresa.valid ? '' : 'error_input ') +
                      (this.state.nome_empresa.first ? 'first_input' : '')
                    }
                    placeholder='digite o nome da empresa'
                    value={this.state.nome_empresa.value}
                    onChange={text => {
                      if (text.target.value !== '') {
                        this.setState({
                          nome_empresa: {
                            first: false,
                            value: text.target.value,
                            valid: true
                          }
                        })
                      } else {
                        this.setState({
                          nome_empresa: {
                            first: false,
                            value: text.target.value,
                            valid: false
                          }
                        })
                      }
                    }}
                    onBlur={() => {
                      if (this.state.nome_empresa.valid) {
                        updateData(
                          'nome_empresa',
                          this.state.nome_empresa.value,
                          true
                        )
                      } else {
                        updateData(
                          'nome_empresa',
                          this.state.nome_empresa.value,
                          false
                        )
                      }
                    }}
                  />
                </div>

                {/* Documento */}
                <div className='form-group'>
                  <label htmlFor='cnpj' className='label'>
                    CNPJ / CPF
                  </label>

                  <InputMask
                    mask={dynamicMask(this.state.cnpj.value)}
                    maskPlaceholder='*'
                    type='text'
                    placeholder='CNPJ / CPF'
                    className={
                      'form-control col-12 ' +
                      (this.state.cnpj.valid ? '' : 'error_input ') +
                      (this.state.cnpj.first ? 'first_input' : '')
                    }
                    value={this.state.cnpj.value}
                    onChange={text => {
                      if (this.validDocument(text.target.value)) {
                        this.setState({
                          cnpj: {
                            first: false,
                            value: text.target.value,
                            valid: true,
                            justNumbers: text.target.value.replace(/\D/g, '')
                          }
                        })
                      } else {
                        this.setState({
                          cnpj: {
                            first: false,
                            value: text.target.value,
                            valid: false
                          }
                        })
                      }
                    }}
                    onBlur={text => {
                      if (
                        this.validDocument(text.target.value) &&
                        this.state.cnpj.valid
                      ) {
                        updateData('cnpj', this.state.cnpj.justNumbers, true)
                        toast('documento válido', {
                          className: 'toast-background',
                          bodyClassName: 'toast-body',
                          progressClassName: 'toast-progress',
                          position: 'top-right',
                          hideProgressBar: true,
                          style: {
                            borderRadius: '10px'
                          }
                        })
                      } else {
                        updateData('cnpj', this.state.cnpj.value, false)
                        toast(
                          <div>
                            <div>Documento Inválido</div>
                            <div
                              style={{
                                borderTop: '1px dashed rgba(255,255,255,1)',
                                fontSize: '12px',
                                padding: '5px 0',
                                marginTop: '5px'
                              }}
                            >
                              por favor verifique se digitou corretamente
                            </div>
                          </div>,
                          {
                            className: 'toast-red',
                            bodyClassName: 'toast-body',
                            progressClassName: 'toast-progress',
                            position: 'top-right',
                            hideProgressBar: true,
                            style: {
                              borderRadius: '10px',
                              background: 'red'
                            }
                          }
                        )
                      }
                    }}
                  />
                </div>

                <div className='col-12' />

                {/* Grupo */}
                <div className='form-group'>
                  <label htmlFor='grupo' className='label'>
                    Grupo
                  </label>
                  <input
                    type='text'
                    placeholder='Grupo'
                    className={
                      'form-control col-12 ' +
                      (this.state.grupo.valid ? '' : 'error_input ') +
                      (this.state.grupo.first ? 'first_input' : '')
                    }
                    value={this.state.grupo.value}
                    onChange={text => {
                      if (text.target.value !== '') {
                        this.setState({
                          grupo: {
                            first: false,
                            value: text.target.value,
                            valid: true
                          }
                        })
                      } else {
                        this.setState({
                          grupo: {
                            first: false,
                            value: text.target.value,
                            valid: false
                          }
                        })
                      }
                    }}
                    onBlur={() => {
                      if (this.state.grupo.valid) {
                        updateData('grupo', this.state.grupo.value, true)
                      } else {
                        updateData('grupo', this.state.grupo.value, false)
                      }
                    }}
                  />
                </div>

                <div className='form-group'>
                  <label className='label'>Data base</label>
                  <DatePicker
                    dateFormat='dd/MM/yyyy'
                    locale={ptbr}
                    selected={this.state.dataBase.value}
                    onChange={this.handleChangeDataBasa}
                    className={
                      'form-control ' +
                      (this.state.dataBase.valid ? '' : 'error_input ') +
                      (this.state.dataBase.first ? 'first_input' : '')
                    }
                  />
                </div>

                <div className='col-12' />

                {/* Estado */}
                <div className='form-group'>
                  <label htmlFor='estado' className='label'>
                    Estado
                  </label>
                  <select
                    id='estado'
                    className={
                      'form-control ' +
                      (this.state.estado.valid ? '' : 'error_input ') +
                      (this.state.estado.first ? 'first_input' : '')
                    }
                    onChange={this.handleChangeEstado}
                    // defaultValue={this.state.estado.value}
                    value={this.state.estado.value}
                  >
                    {estados.map(estado => (
                      <option value={estado.Sigla} key={estado.Sigla}>
                        {estado.Sigla}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Cidade */}
                {this.state.showCidades ? (
                  <div className='form-group'>
                    <label htmlFor='cidade' className='label'>
                      Cidade
                    </label>
                    <select
                      id='cidade'
                      onChange={this.handleChangeCidade}
                      // defaultValue={this.state.cidade.value}
                      value={this.state.cidade.value}
                      className={
                        'form-control ' +
                        (this.state.cidade.valid ? '' : 'error_input ') +
                        (this.state.cidade.first ? 'first_input' : '')
                      }
                    >
                      {this.state.cidades.map(cidade => (
                        <option
                          defaultValue={
                            cidade.Nome === dados.cidade
                              ? dados.cidade
                              : 'Selecione'
                          }
                          key={cidade.ID}
                        >
                          {cidade.Nome}
                        </option>
                      ))}
                    </select>
                  </div>
                ) : (
                  <div
                    style={{
                      fontSize: '10px',
                      marginLeft: '10px',
                      marginTop: '10px'
                    }}
                  >
                    selecione um estado primeiro para depois escolher a cidade
                  </div>
                )}

                <div className='col-12' />

                {/* Setor */}
                <div className='form-group'>
                  <label htmlFor='setor' className='label'>
                    Setor
                  </label>
                  <select
                    id='setor'
                    onChange={this.handleChangeSetor}
                    value={this.state.setor.value}
                    className={
                      'form-control ' +
                      (this.state.setor.valid ? '' : 'error_input ') +
                      (this.state.setor.first ? 'first_input' : '')
                    }
                  >
                    {setor.map(setor => (
                      <option value={setor.nome} key={setor.id}>
                        {setor.nome}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Fundacao */}
                <div className='form-group'>
                  <label htmlFor='fundacao' className='label'>
                    Fundação
                  </label>
                  <DatePicker
                    dateFormat='dd/MM/yyyy'
                    locale={ptbr}
                    selected={this.state.fundacao.value}
                    onChange={this.handleChangeDateFundacao}
                    className={
                      'form-control ' +
                      (this.state.fundacao.valid ? '' : 'error_input ') +
                      (this.state.fundacao.first ? 'first_input' : '')
                    }
                  />
                </div>

                {/* Moeda */}
                <div className='form-group'>
                  <label htmlFor='moeda' className='label'>
                    Moeda
                  </label>
                  <select
                    id='moeda'
                    onChange={this.handleChangeMoeda}
                    value={this.state.moeda.value}
                    className={
                      'form-control ' +
                      (this.state.moeda.valid ? '' : 'error_input ') +
                      (this.state.moeda.first ? 'first_input' : '')
                    }
                  >
                    {currency.map(moeda => (
                      <option value={moeda.sigla} key={moeda.id}>
                        {moeda.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className='col-12' />

                {/* Gerente */}
                <div className='form-group'>
                  <label htmlFor='grupo' className='label'>
                    Gerente da Conta
                  </label>
                  <input
                    type='text'
                    placeholder='Gerente da conta'
                    className={
                      'form-control col-12 ' +
                      (this.state.gerente.valid ? '' : 'error_input ') +
                      (this.state.gerente.first ? 'first_input' : '')
                    }
                    value={this.state.gerente.value}
                    onChange={text => {
                      if (text.target.value !== '') {
                        this.setState({
                          gerente: {
                            first: false,
                            value: text.target.value,
                            valid: true
                          }
                        })
                      } else {
                        this.setState({
                          gerente: {
                            first: false,
                            value: text.target.value,
                            valid: false
                          }
                        })
                      }
                    }}
                    onBlur={() => {
                      if (this.state.gerente.valid) {
                        updateData('gerente', this.state.gerente.value, true)
                      } else {
                        updateData('gerente', this.state.gerente.value, false)
                      }
                    }}
                  />
                </div>

                {/* Plataforma */}
                <div className='form-group'>
                  <label htmlFor='grupo' className='label'>
                    Plataforma
                  </label>
                  <input
                    type='text'
                    placeholder='Plataforma'
                    className={
                      'form-control col-12 ' +
                      (this.state.plataforma.valid ? '' : 'error_input ') +
                      (this.state.plataforma.first ? 'first_input' : '')
                    }
                    value={this.state.plataforma.value}
                    onChange={text => {
                      if (text.target.value !== '') {
                        this.setState({
                          plataforma: {
                            first: false,
                            value: text.target.value,
                            valid: true
                          }
                        })
                      } else {
                        this.setState({
                          plataforma: {
                            first: false,
                            value: text.target.value,
                            valid: false
                          }
                        })
                      }
                    }}
                    onBlur={() => {
                      if (this.state.plataforma.valid) {
                        updateData(
                          'plataforma',
                          this.state.plataforma.value,
                          true
                        )
                      } else {
                        updateData(
                          'plataforma',
                          this.state.plataforma.value,
                          false
                        )
                      }
                    }}
                  />
                </div>

                <div className='col-12' />

                {/* Observação */}
                <div
                  className='form-group col-12'
                  style={{ marginLeft: '0px', paddingLeft: '0px' }}
                >
                  <label
                    htmlFor='grupo'
                    className='label'
                    style={{ display: 'block', width: '100%' }}
                  >
                    Comentários
                  </label>
                  <textarea
                    rows={3}
                    placeholder='Comentarios'
                    className={
                      'form-control col-5 ' +
                      (this.state.comentarios.valid ? '' : 'error_input ') +
                      (this.state.comentarios.first ? 'first_input' : '')
                    }
                    value={this.state.comentarios.value}
                    onChange={text => {
                      if (text.target.value !== '') {
                        this.setState({
                          comentarios: {
                            first: false,
                            value: text.target.value,
                            valid: true
                          }
                        })
                      } else {
                        this.setState({
                          comentarios: {
                            first: false,
                            value: text.target.value,
                            valid: false
                          }
                        })
                      }
                    }}
                    onBlur={() => {
                      if (this.state.comentarios.valid) {
                        updateData(
                          'comentarios',
                          this.state.comentarios.value,
                          true
                        )
                      } else {
                        updateData(
                          'comentarios',
                          this.state.comentarios.value,
                          false
                        )
                      }
                    }}
                  />
                </div>

                {IS_SULBRASIL && (
                  <div className='row align-items-center mt-4 w-100'>
                    {this.props.isGroup === undefined && (
                      <>
                        {this.props.dados.groupExists &&
                          this.props.dados.datas.length > 0 && (
                            <button
                              className='btn btn-outline-primary ml-3'
                              type='button'
                              onClick={async event => {
                                event.preventDefault()
                                let isConfirmed

                                if (this.props.dados.datas.length > 0) {
                                  isConfirmed = true
                                }
                                this.props.setGroup(isConfirmed)
                                this.changeToGroupMode()
                              }}
                            >
                              <MdGroup style={{ fontSize: '20px' }} />
                              <span
                                style={{
                                  fontWeight: 'bold',
                                  marginLeft: '10px'
                                }}
                              >
                                acessar spread consolidado
                              </span>
                            </button>
                          )}
                      </>
                    )}
                  </div>
                )}
                {IS_SULBRASIL && this.props.isGroup && (
                  <>
                    <div className='divisor' />
                    <div
                      className='h4'
                      style={{ fontWeight: 'bold', color: 'rgb(83, 90, 208)' }}
                    >
                      Documentos do grupo
                    </div>
                    <div className='container-fluid'>
                      {(this.props.dados.empresas_consolidadas || []).map(
                        (item, index) => (
                          <div className='row my-2' key={index.toString()}>
                            <div className='col-12'>
                              <InputMask
                                mask={dynamicMask(item.value)}
                                maskPlaceholder='*'
                                type='text'
                                placeholder='CNPJ / CPF'
                                className={
                                  'form-control ' +
                                  (item.valid ? '' : 'error_input ')
                                }
                                readOnly
                                value={item.value}
                              />
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </>
                )}

                {this.props.isGroup === false ||
                this.props.isGroup === undefined ||
                !IS_SULBRASIL ||
                this.props.dados.datas.length > 0 ? (
                  <>
                    <div className='divisor' />

                    <div className='row justify-content-start align-items-start'>
                      <div
                        className='form-group col-auto'
                        style={{ margin: 0, padding: 0, marginBottom: '10px' }}
                      >
                        <button
                          className='btn btn-sm btn-outline-primary'
                          onClick={event => {
                            event.preventDefault()
                            const dataTemp = {
                              data: new Date(),
                              value: 0,
                              auditado: false,
                              combinado: false,
                              consolidado: false,
                              enabled: false
                            }

                            addData(dataTemp, dados.datas.length)
                          }}
                        >
                          <MdAddCircle style={{ fontSize: '20px' }} />
                          <span
                            style={{ fontWeight: 'bold', marginLeft: '10px' }}
                          >
                            Adicionar Data
                          </span>
                        </button>
                        <button
                          className='ml-4 btn btn-sm btn-outline-primary'
                          type='button'
                          onClick={async event => {
                            event.preventDefault()
                            await this.handleSortDates()
                          }}
                        >
                          <MdSort style={{ fontSize: '20px' }} />
                          <span
                            style={{ fontWeight: 'bold', marginLeft: '10px' }}
                          >
                            Ordenar datas
                          </span>
                        </button>
                      </div>
                      <div className='col-12'>
                        {dados.datas.map((el, key) => (
                          <DadosTableRow
                            key={`${key}-${el.data}`}
                            even={el.even}
                            data={el.data}
                            auditado={el.auditado}
                            consolidado={el.consolidado}
                            combinado={el.combinado}
                            selected={el}
                            el={el}
                          />
                        ))}
                      </div>
                    </div>
                  </>
                ) : null}

                <div className='col-12' />
              </form>

              <Footer />
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  dados: state.dados,
  ativo: state.ativo,
  passivo: state.passivo,
  dre: state.dre,
  mutacao: state.mutacao,
  fundos: state.fundos,
  parceiros: state.parceiros,
  rating: state.rating,

  api: state.api,
  isGroup: state.sidebar.isGroup
})

const mapDispatchToProps = dispatch => ({
  addData: (dataItem, qtdeDatas) => {
    dispatch(DadosActions.addData(dataItem))
    dispatch(AtivoActions.addDataAtivo())
    dispatch(PassivoActions.addDataPassivo())
    dispatch(MutacaoActions.addDataMutacao())
    dispatch(DREAction.addDataDRE())
  },
  increaseProgress: () => dispatch(DadosActions.increaseProgress()),
  decreaseProgress: () => dispatch(DadosActions.decreaseProgress()),
  saveData: data => dispatch(DadosActions.saveData(data)),
  updateSimpleData: (key, value) =>
    dispatch(DadosActions.updateSimpleData(key, value)),
  clickSidebar: item => dispatch(SidebarActions.clickSidebar(item)),
  updateData: (key, value, status) =>
    dispatch(DadosActions.updateData(key, value, status)),
  bootstrap_dados: data => dispatch(DadosActions.bootstrap(data)),
  bootstrap_ativo: data => dispatch(AtivoActions.bootstrap(data)),
  saveServer: () => dispatch(DadosActions.saveDataServer()),
  lockDates: () => dispatch(DadosActions.lockDates()),
  setGroup: group => dispatch(SidebarActions.setGroup(group)),
  dispatch
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dados))

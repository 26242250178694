import { factoryDate } from '../../../utils'

const INITIAL_STATE = {
  nome_empresa: '',
  cnpj: '',
  grupo: '',
  cidade: '',
  estado: '',
  setor: '',
  fundacao: '',
  dataBase: '',
  moeda: '',
  datas: [],
  gerente: '',
  plataforma: '',
  comentarios: '',
  empresas_consolidadas: [],
  progress: {
    nome_empresa: false,
    cnpj: false,
    grupo: false,
    cidade: false,
    estado: false,
    setor: false,
    fundacao: false,
    dataBase: false,
    moeda: false,
    gerente: false,
    plataforma: false
  },
  progress_show_tooltip: false,
  show_cidade: false,
  groupExists: null,
  is_saved: false
}

export default function data (state = INITIAL_STATE, action) {
  if (action.type === 'DADOS/UPDATE_FROM_REDUX_STYLE') {
    return {
      ...state,
      ...action.data
    }
  }

  if (action.type === 'BOOTSTRAP') {
    if (action.data) {
      return {
        ...state,
        nome_empresa: action.data.nome,
        cnpj: action.data.cnpj,
        grupo: action.data.grupo,
        cidade: action.data.cidade,
        estado: action.data.estado,
        setor: action.data.setor,
        fundacao: action.data.data_fundacao
          ? factoryDate(action.data.data_fundacao)
          : null,
        dataBase: action.data.data_base
          ? factoryDate(action.data.data_base)
          : null,
        moeda: action.data.moeda,
        datas: action.data.datas.map(data => {
          data.data = new Date(data.data.replace('Z', ''))
          return data
        }),
        progress: { ...state.progress, nome_empresa: true, cnpj: true },
        gerente: action.data.gerente,
        plataforma: action.data.plataforma,
        comentarios: action.data.comentarios,
        groupExists: action.data.groupExists,
        empresas_consolidadas: action.data.empresas_consolidadas
          ? action.data.empresas_consolidadas
          : [],
        is_saved: action.data.datas.length > 0
      }
    }
  }

  if (action.type === 'DADOS_DATA_ADD') {
    // A linha abaixo serve para modifica os id's de todo mundo para que não acontece de ter duas entradas com o mesmo id
    const newDatas = (state.datas || [])
      .concat(action.data_item)
      .map((item, index) => ({
        ...item,
        id: index + 1,
        even: (index + 1) % 2 === 0
      }))
    return {
      ...state,
      datas: newDatas
    }
  }
  if (action.type === 'CHANGE_BALANCO_ITEM') {
    return {
      ...state,
      datas: state.datas.map(data =>
        data.id === action.item.id ? { ...data, data: action.data } : data
      )
    }
  }
  if (action.type === 'TOGGLE_AUDITADO') {
    return {
      ...state,
      datas: state.datas.map((data, key) =>
        key === action.item.id ? { ...data, auditado: !data.auditado } : data
      )
    }
  }

  if (action.type === 'TOGGLE_CONSOLIDADO') {
    return {
      ...state,
      datas: state.datas.map((data, key) =>
        key === action.item.id
          ? { ...data, consolidado: !data.consolidado }
          : data
      )
    }
  }

  if (action.type === 'TOGGLE_COMBINADO') {
    return {
      ...state,
      datas: state.datas.map((data, key) =>
        key === action.item.id ? { ...data, combinado: !data.combinado } : data
      )
    }
  }

  if (action.type === 'REMOVE_DATA') {
    return {
      ...state,
      datas: state.datas.filter(item => item.id !== action.item.id)
    }
    // console.log('remove data', action);
  }

  if (action.type === 'INCREASE_PROGRESS') {
    const value = state.progress + state.progressValue
    return {
      ...state,
      progress: value
    }
  }

  if (action.type === 'DECREASE_PROGRESS') {
    const value = state.progress - state.progressValue
    return {
      ...state,
      progress: value
    }
  }

  if (action.type === 'SAVE_DATA') {
    return { ...state, nome_empresa: action.data.nome_empresa }
  }

  if (action.type === 'UPDATE_DATA') {
    return {
      ...state,
      [action.key]: action.value,
      progress: {
        ...state.progress,
        [action.key]: action.status
      }
    }
  }

  if (action.type === 'UPDATE_SIMPLE_DATA') {
    return {
      ...state,
      [action.key]: action.value
    }
  }

  if (action.type === 'SAVE_DATA_SERVER') {
    return {
      ...state,
      is_saved: true
    }
  }

  if (action.type === 'LOCK_DATES') {
    return {
      ...state,
      datas: state.datas.map(data => ({
        ...data,
        enabled: true
      }))
    }
  }
  return state
}
